import { api } from 'constants/API';
import { TestLocators } from 'constants/Locators';

import { Button, Divider, Typography } from '@material-ui/core';
import axios from 'axios';
import cn from 'clsx';
import { CustomLink } from 'impact-react-components';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TrackVirtualPageView } from 'app/components/common/TrackVirtualPageView/TrackVirtualPageView';
import { actions as oktaActions } from 'app/components/pages/OktaLoginPage/slice';
import { selectErrorMessage } from 'app/containers/PrivateRoute/selectors';
import { actions } from 'app/containers/PrivateRoute/slice';
import { domainName } from 'helpers/domain';
import { SAM_JWT_ACCESS_TOKEN, SELECTED_DATABASE } from 'utils/cookie';

import { supportedErrorTypes } from './constants';
import { useStyles } from './styles';

export function ForbiddenPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [description, setDescription] = useState(t(`FORBIDDEN.DESCRIPTION`));
  const dispatch = useDispatch();
  const errorMessage = useSelector(selectErrorMessage);
  const translationKey = Object.keys(supportedErrorTypes).find((key) => supportedErrorTypes[key] === errorMessage);

  const handleClose = () => {
    dispatch(oktaActions.clearUserInfoFromOkta());
    document.cookie = `${SAM_JWT_ACCESS_TOKEN}= ; Secure; Domain=${domainName()}; path=/;`;
    document.cookie = `${SELECTED_DATABASE}= ; Domain=${domainName()}; path=/;`;
    axios.delete(api.logout).finally(() => {
      window.location.href = `${process.env.REACT_APP_IHS_AUTH_URL}/clearsession`;
    });
  };

  useEffect(() => {
    dispatch(actions.clearUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (translationKey) {
      setDescription(t(`FORBIDDEN.${translationKey}`));
    }
  }, [dispatch, t, translationKey]);

  return (
    <>
      <Helmet>
        <title>{t(`FORBIDDEN.META_TITLE`)}</title>
      </Helmet>
      <TrackVirtualPageView pageTitle={t(`FORBIDDEN.META_TITLE`)} />
      <div className={classes.wrapper}>
        <div className={classes.card}>
          <div className={classes.text}>
            <Typography className={classes.title}>{t(`FORBIDDEN.ERROR`)}</Typography>
            <Divider className={classes.divider} />
            <div className={classes.infoWrapper}>
              <Typography component="span" className={classes.info} data-testid={TestLocators.NoAccess_Msge}>
                {description}
              </Typography>
            </div>
            <div className={classes.infoWrapper}>
              <Typography component="span" className={classes.info} data-testid={TestLocators.NoAccess_Contact_Msge}>
                {t(`FORBIDDEN.SUB_DESCRIPTION.PHONE_TEXT`)}
              </Typography>
              <CustomLink
                className={cn(classes.info, classes.link)}
                href={`tel:${t(`FORBIDDEN.SUB_DESCRIPTION.PHONE_LINK`)}`}
                text={t(`FORBIDDEN.SUB_DESCRIPTION.PHONE_LINK`)}
                data-testid={TestLocators.NoAccess_Phone}
              />
              <Typography component="span" className={classes.info} data-testid={TestLocators.NoAccess_Or_Msge}>
                {t(`FORBIDDEN.SUB_DESCRIPTION.EMAIL_TEXT`)}
              </Typography>
              <CustomLink
                className={cn(classes.info, classes.link)}
                href={`mailto:${t(`FORBIDDEN.SUB_DESCRIPTION.EMAIL_LINK`)}`}
                text={t(`FORBIDDEN.SUB_DESCRIPTION.EMAIL_LINK`)}
                data-testid={TestLocators.NoAccess_Email}
              />
            </div>
          </div>
          <div className={cn(classes.infoWrapper, classes.buttonContainer)} onClick={handleClose}>
            <Button className={classes.button}>{t('COMMON.CLOSE')}</Button>
          </div>
        </div>
      </div>
    </>
  );
}
